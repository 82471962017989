var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!_vm.showMails.length)?_c('v-card',{staticClass:"text-center py-2",attrs:{"elevation":"0","dark":_vm.$store.getters.theme === 'dark' ? true : false,"color":"transparent"}},[_c('div',[_vm._v("No Mails Found")])]):_c('v-data-table',{staticClass:"data-table",attrs:{"dark":_vm.$store.getters.theme === 'dark' ? true : false,"no-data-text":"No Mails Found","options":_vm.options,"hide-default-footer":"","hide-default-header":"","loading":_vm.tableLoading,"headers":[{ value: 'details' }, { value: 'time' }],"items":_vm.showMails,"item-key":"parentId"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items.entries()),function(ref){
var index = ref[0];
var item = ref[1];
return _c('tr',{key:item._id,staticClass:"tr",class:item.seen && item.seen.includes(_vm.userInfo.nickName)
                        ? 'read-mail'
                        : _vm.routePath == 'sent'
                        ? 'read-mail'
                        : ''},[_c('td',{staticClass:"py-2 td td-mail",attrs:{"title":_vm.getSingleUserInfo(item.receiver[0])},on:{"click":function($event){return _vm.checkItem(item, index)}}},[_c('v-list-item',{staticClass:"pr-0"},[(
                                (_vm.userInfo.nickName == item.sender ||
                                    _vm.userInfo.domains.includes(
                                        item.sender
                                    )) &&
                                _vm.$route.name != 'inbox'
                            )?[_c('v-avatar',{staticClass:"main-btn mr-3 text-bold",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(item.sender ? item.sender .slice(1, 3) .toUpperCase() : "##")+" ")])]:(item.receiver.length > 1)?[_c('span',{staticClass:"multi-avatar"},[_c('v-avatar',{staticClass:"main-btn mr-3 first-avatar",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(item.receiver[0] .slice(1, 3) .toUpperCase())+" ")]),_c('v-avatar',{staticClass:"main-btn second-avatar",attrs:{"size":"40"}},[_vm._v(" ## ")])],1)]:[_c('v-avatar',{staticClass:"main-btn mr-3",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(item.receiver[0] ? item.receiver[0] .slice(1, 3) .toUpperCase() : "##")+" ")])],_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[(
                                        _vm.userInfo.nickName == item.sender ||
                                        _vm.userInfo.domains.includes(
                                            item.sender
                                        )
                                    )?_c('span',_vm._l((item.receiver),function(rec,index){return _c('span',{key:index,attrs:{"title":_vm.getSingleUserInfo(rec, item)}},[_vm._v(" "+_vm._s(rec)+" "),(
                                                index !==
                                                item.receiver.length - 1
                                            )?_c('span',[_vm._v(", ")]):_vm._e()])}),0):_c('span',{attrs:{"title":_vm.getSingleUserInfo(item.sender, item)}},[_vm._v(" "+_vm._s(item.sender)+" ")])]),_c('div',{staticClass:"mt-2"},[(
                                        _vm.$route.name !== 'inbox' &&
                                        _vm.$route.name !== 'sent'
                                    )?_c('v-chip',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.userInfo.nickName == item.sender ? "sent" : "inbox")+" ")]):_vm._e(),(item.attachments.length)?_c('v-btn',{staticClass:"mr-1 pa-0",attrs:{"elevation":"0","min-width":"auto","color":"transparent","height":"auto","title":'Has ' +
                                        item.attachments.length +
                                        ' attachment(s)'}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")]),_c('small',[_vm._v("x")]),_vm._v(" "+_vm._s(item.attachments.length)+" ")],1):_vm._e(),_c('span',{staticClass:"mail-subject"},[_vm._v(_vm._s(item.subject))])],1)],1)],2)],1),_c('td',{staticClass:"td td-time"},[_c('v-container',{staticClass:"additions"},[_c('v-row',[_c('v-col',[(_vm.$route.name !== 'archive')?_c('v-layout',{attrs:{"justify-end":""}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                                                        'moveToArchive',
                                                        item
                                                    )}}},on),[_c('v-icon',[_vm._v(" mdi-package-down ")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive")])]),_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu,
                                                            onTooltip)),[_c('v-icon',[_vm._v(" mdi-folder-move ")])],1)]}}],null,true)},[_c('span',[_vm._v("Move to")])])]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"200px"}},_vm._l((_vm.foldersList),function(folderName,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit(
                                                        'moveToFolder',
                                                        item,
                                                        index,
                                                        folderName
                                                    )}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(folderName)+" ")])],1)}),1)],1)],1):_c('v-layout',{attrs:{"justify-end":""}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                                                        'unArchiveMail',
                                                        item
                                                    )}}},on),[_c('v-icon',[_vm._v(" mdi-package-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("Undo")])])],1)],1)],1)],1),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.timeFormate(item.timestamp))+" ")])],1)])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }