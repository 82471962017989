<template>
  <v-card
    elevation="4"
    class="px-5 py-4 glb-border-top"
    rounded
    :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
    :dark="$store.getters.theme === 'dark' ? true : false"
  >
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-btn
          elevation="0"
          color="transparent"
          x-large
          class="btn-disable px-0"
        >
          <v-icon class="mr-2"> {{ tIcon }}</v-icon>
          <span class="">{{ title }} </span>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-combobox
          v-if="this.$route.name !== 'user folders'"
          :items="allNames"
          v-model="selectedNames"
          @change="changedSelectionName()"
          width="300px"
          background-color="#ffffff4d"
          border="1px"
          prepend-inner-icon="mdi-filter"
          hide-details
          clearable
          placeholder="All"
          rounded
          dense
          outlined
          multiple
        ></v-combobox>
      </v-col>

      <v-spacer class="d-none d-sm-flex"></v-spacer>
      <v-col cols="auto" v-if="totalMails">
        <div class="text-body-2">
          <v-row align="center" class="ma-0" no-gutters>
            <v-btn text @click="refresh()">
              <v-icon left>mdi-refresh</v-icon>
              Refresh
            </v-btn>
            <v-col cols="auto ">
              {{ from }} - {{ to }} of {{ totalMails }}
            </v-col>
            <v-col cols="auto">
              <v-btn
                icon
                :disabled="from > 1 ? false : true"
                @click="$emit('changePage', --pageNumber)"
              >
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn icon :disabled="to >= totalMails ? true : false">
                <v-icon @click="$emit('changePage', ++pageNumber)">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    selectAll: {
      require: true,
    },
    totalMails: {
      require: true,
      default: 0,
    },
    mailsPerPage: {
      default: 50,
    },
    page: {
      default: 1,
    },
    title: String,
    tIcon: String,
  },
  created() {
    // this.$emit("changePage", this.pageNumber);

    setTimeout(() => {
      this.indeterminate = false;
    }, 10);
  },
  data() {
    return {
      selectAllLocal: false,
      indeterminate: false,
      pageNumber: this.page,
      selectedNames: [],
    };
  },
  methods: {
    async changedSelectionName() {
      this.$emit("updatingMails");

      if (this.selectedNames.length === 0) {
        await this.$store.dispatch("REFRESH_MAILS", "INBOX");
        this.$emit("updateMails");
      } else {
        let methodName = "GET_FILTERED_INBOX_MAILS";

        if (this.$route.name == "sent") methodName = "GET_FILTERED_SENT_MAILS";
        else if (this.$route.name == "archive")
          methodName = "GET_FILTERED_ARCHIVE_MAILS";

        await this.$store.dispatch(methodName, {
          limit: 50,
          pageNo: 1,
          selectedNames: this.selectedNames,
        });

        this.$emit("updateMails");
      }
    },
    async refresh() {
      this.$emit("updatingMails");

      if (this.$route.name == "inbox") {
        await this.$store.dispatch("REFRESH_MAILS", "INBOX");
        this.$emit("updateMails");
      } else if (this.$route.name == "sent") {
        await this.$store.dispatch("REFRESH_MAILS", "SENT");
        this.$emit("updateMails");
      } else if (this.$route.name == "archive") {
        await this.$store.dispatch("REFRESH_MAILS", "ARCHIVE");
        this.$emit("updateMails");
      } else {
        // const index = Object.keys(this.foldersList).findIndex(
        //   (ke) => ke == this.$route.params.tagName
        // );
        // this.selectedMainNav = 3 + index;
        // this.$store.dispatch("REFRESH_MAILS", "INBOX");
      }
    },
  },
  watch: {
    selectAll(newVal) {
      if (this.selectAllLocal !== newVal) this.indeterminate = true;
    },
    page(newVal) {
      this.pageNumber = newVal;
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    allNames() {
      return this.userInfo.nickName
        ? [this.userInfo.nickName].concat(this.userInfo.domains)
        : [];
    },

    from() {
      return this.mailsPerPage * (this.pageNumber - 1) + 1;
    },
    to() {
      return this.from + this.mailsPerPage - 1 < this.totalMails
        ? this.from + this.mailsPerPage - 1
        : this.totalMails;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-icon {
  color: #1976d2;
}

.btn-disable:hover {
  cursor: inherit;
  background: transparent;

  &::before {
    opacity: 0;
  }
}
</style>
