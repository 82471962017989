<template>
    <section>
        <!-- <v-card elevation="0" color="transparent">
      <div>asdf</div>
    </v-card>
    <v-divider></v-divider> -->
        <!-- :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'" -->
        <v-card
            class="text-center py-2"
            elevation="0"
            v-if="!showMails.length"
            :dark="$store.getters.theme === 'dark' ? true : false"
            color="transparent"
        >
            <!-- :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'" -->
            <div>No Mails Found</div>
        </v-card>

        <v-data-table
            v-else
            class="data-table"
            :dark="$store.getters.theme === 'dark' ? true : false"
            no-data-text="No Mails Found"
            :options="options"
            hide-default-footer
            hide-default-header
            :loading="tableLoading"
            :headers="[{ value: 'details' }, { value: 'time' }]"
            :items="showMails"
            item-key="parentId"
        >
            <template v-slot:body="{ items }">
                <tr
                    class="tr"
                    :class="
                        item.seen && item.seen.includes(userInfo.nickName)
                            ? 'read-mail'
                            : routePath == 'sent'
                            ? 'read-mail'
                            : ''
                    "
                    v-for="[index, item] in items.entries()"
                    :key="item._id"
                >
                    <td
                        @click="checkItem(item, index)"
                        class="py-2 td td-mail"
                        :title="getSingleUserInfo(item.receiver[0])"
                    >
                        <v-list-item class="pr-0">
                            <template
                                v-if="
                                    (userInfo.nickName == item.sender ||
                                        userInfo.domains.includes(
                                            item.sender
                                        )) &&
                                    $route.name != 'inbox'
                                "
                            >
                                <v-avatar
                                    size="40"
                                    class="main-btn mr-3 text-bold"
                                >
                                    {{
                                        item.sender
                                            ? item.sender
                                                  .slice(1, 3)
                                                  .toUpperCase()
                                            : "##"
                                    }}
                                </v-avatar>
                            </template>
                            <template v-else-if="item.receiver.length > 1">
                                <span class="multi-avatar">
                                    <v-avatar
                                        size="40"
                                        class="main-btn mr-3 first-avatar"
                                    >
                                        {{
                                            item.receiver[0]
                                                .slice(1, 3)
                                                .toUpperCase()
                                        }}
                                    </v-avatar>
                                    <v-avatar
                                        size="40"
                                        class="main-btn second-avatar"
                                    >
                                        ##
                                    </v-avatar>
                                </span>
                            </template>
                            <template v-else>
                                <v-avatar size="40" class="main-btn mr-3">
                                    {{
                                        item.receiver[0]
                                            ? item.receiver[0]
                                                  .slice(1, 3)
                                                  .toUpperCase()
                                            : "##"
                                    }}
                                </v-avatar>
                            </template>

                            <v-list-item-content class="py-0">
                                <v-list-item-title>
                                    <span
                                        v-if="
                                            userInfo.nickName == item.sender ||
                                            userInfo.domains.includes(
                                                item.sender
                                            )
                                        "
                                    >
                                        <span
                                            v-for="(
                                                rec, index
                                            ) in item.receiver"
                                            :title="
                                                getSingleUserInfo(rec, item)
                                            "
                                            :key="index"
                                        >
                                            {{ rec }}
                                            <span
                                                v-if="
                                                    index !==
                                                    item.receiver.length - 1
                                                "
                                                >,
                                            </span>
                                        </span>
                                    </span>
                                    <span
                                        :title="
                                            getSingleUserInfo(item.sender, item)
                                        "
                                        v-else
                                    >
                                        {{ item.sender }}
                                    </span>
                                </v-list-item-title>

                                <div class="mt-2">
                                    <v-chip
                                        v-if="
                                            $route.name !== 'inbox' &&
                                            $route.name !== 'sent'
                                        "
                                        small
                                        class="mr-1"
                                    >
                                        {{
                                            userInfo.nickName == item.sender
                                                ? "sent"
                                                : "inbox"
                                        }}
                                    </v-chip>
                                    <v-btn
                                        v-if="item.attachments.length"
                                        class="mr-1 pa-0"
                                        elevation="0"
                                        min-width="auto"
                                        color="transparent"
                                        height="auto"
                                        :title="
                                            'Has ' +
                                            item.attachments.length +
                                            ' attachment(s)'
                                        "
                                    >
                                        <v-icon small>mdi-paperclip</v-icon>
                                        <small>x</small>
                                        {{ item.attachments.length }}
                                    </v-btn>

                                    <span class="mail-subject">{{
                                        item.subject
                                    }}</span>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </td>
                    <td class="td td-time">
                        <v-container class="additions">
                            <v-row>
                                <v-col>
                                    <v-layout
                                        v-if="$route.name !== 'archive'"
                                        justify-end
                                    >
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    icon
                                                    @click="
                                                        $emit(
                                                            'moveToArchive',
                                                            item
                                                        )
                                                    "
                                                    v-on="on"
                                                >
                                                    <v-icon>
                                                        mdi-package-down
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Archive</span>
                                        </v-tooltip>
                                        <v-menu left>
                                            <template
                                                v-slot:activator="{
                                                    on: onMenu,
                                                }"
                                            >
                                                <v-tooltip left>
                                                    <template
                                                        v-slot:activator="{
                                                            on: onTooltip,
                                                        }"
                                                    >
                                                        <v-btn
                                                            icon
                                                            v-on="{
                                                                ...onMenu,
                                                                ...onTooltip,
                                                            }"
                                                        >
                                                            <v-icon>
                                                                mdi-folder-move
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Move to</span>
                                                </v-tooltip>
                                            </template>
                                            <v-list
                                                style="max-height: 200px"
                                                class="overflow-y-auto"
                                            >
                                                <v-list-item
                                                    v-for="(
                                                        folderName, index
                                                    ) in foldersList"
                                                    :key="index"
                                                    @click="
                                                        $emit(
                                                            'moveToFolder',
                                                            item,
                                                            index,
                                                            folderName
                                                        )
                                                    "
                                                >
                                                    <v-list-item-title>
                                                        {{ folderName }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-layout>
                                    <v-layout v-else justify-end>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    icon
                                                    @click="
                                                        $emit(
                                                            'unArchiveMail',
                                                            item
                                                        )
                                                    "
                                                    v-on="on"
                                                >
                                                    <v-icon>
                                                        mdi-package-up
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Undo</span>
                                        </v-tooltip>
                                    </v-layout>
                                </v-col>
                            </v-row>
                        </v-container>
                        <div class="time">
                            {{ timeFormate(item.timestamp) }}
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["showMails", "tableLoading", "userInfo", "routePath"],
    data: () => ({ options: { itemsPerPage: 50, page: 1 } }),
    computed: {
        ...mapGetters({ foldersList: "foldersList" }),
        userNames() {
            return this.$store.getters.userNames;
        },
    },

    methods: {
        checkItem(item) {
            // if (!item.seen.includes(this.userInfo.nickName))
            //   item.seen.push(this.userInfo.nickName);

            if (this.routePath == "folders") {
                this.$router.push(
                    `/mail/folder/${this.$route.params.tagName}/${item.parentId}`
                );
            } else
                this.$router.push(`/mail/${this.routePath}/${item.parentId}`);
        },
        timeFormate(date) {
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            date = new Date(date);
            var OneDay = new Date().getTime() - 1 * 12 * 60 * 60 * 1000;
            // var OneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
            OneDay = new Date(OneDay);

            if (OneDay < date) {
                var hours = date.getHours(),
                    minutes = date.getMinutes(),
                    am_pm = hours >= 12 ? "PM" : "AM";

                hours = hours % 12;
                hours = hours ? hours : 12;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                const strTime = hours + ":" + minutes + " " + am_pm;

                return strTime;
            } else {
                var month = "" + monthNames[date.getMonth()],
                    day = "" + date.getDate();
                // year = date.getFullYear();

                month = month.substr(0, 3);
                if (day.length < 2) day = "0" + day;
                const strTime = [day, month].join(" ");

                return strTime;
            }
        },
        getSingleUserInfo(lookUpName) {
            if (!lookUpName) return "";

            if (lookUpName == this.userInfo.nickName)
                return "Me <" + lookUpName + ">";
            if (this.userInfo.domains.includes(lookUpName))
                return "Me <" + lookUpName + ">";

            lookUpName = lookUpName.toLowerCase();

            const senderDetails = this.userNames[lookUpName];

            if (
                !senderDetails ||
                !senderDetails.firstName ||
                senderDetails.firstName == ""
            )
                return "<" + lookUpName + ">";

            return (
                senderDetails.firstName +
                " " +
                senderDetails.lastName +
                " " +
                "<" +
                lookUpName +
                ">"
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.multi-avatar {
    position: relative;

    .second-avatar {
        position: absolute;
        left: 5px;
        top: -1px;
        z-index: 0;
        // box-shadow: 1px 0px 0px 1px white;
    }
    .first-avatar {
        z-index: 1;
        position: relative;
        // box-shadow: 1px 0px 0px 1px white;
    }
}

.data-table {
    background: transparent !important;
}

.glb-dark {
    .tr:hover {
        background: #00000080;
    }
    .tr {
        td {
            border-bottom: 1px solid #ffffff26;

            &:first-child {
                border-left: 2px solid #02569d;

                &:hover {
                    border-left: 5px solid #02569d;
                }
            }
        }
        .td-time {
            border-right: 2px solid #02569d;
        }
    }
    .second-avatar,
    .first-avatar {
        box-shadow: 1px 0px 0px 1px black;
    }
}

.glb-white {
    .tr:hover {
        background: #ffffff80;
    }
    .tr {
        td {
            border-bottom: 1px solid #00000026;

            &:first-child {
                border-left: 2px solid #000000b3;

                &:hover {
                    border-left: 5px solid #000000b3;
                }
            }
        }
        .td-time {
            border-right: 2px solid #000000b3;
        }
    }
    .second-avatar,
    .first-avatar {
        box-shadow: 1px 0px 0px 1px white;
    }

    // .td-time {
    //   border-right: 2px solid transparent;
    // }
}

.tr {
    font-weight: 700;

    td {
        border-bottom: 1px solid #ffffff26;
    }

    .additions {
        display: none;
    }
    .time {
        display: block;
    }

    &:hover {
        // background: #00000080;
        cursor: pointer;

        .additions {
            display: block;
        }
        .time {
            display: none;
        }
    }
}

.mail-subject {
    font-size: 1.2em;
    font-weight: 700;
}

::v-deep .v-data-table-header {
    display: none !important;
}

.td {
    font-size: 0.9em;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.td-mail {
    width: 80%;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;

    // &:hover {
    //   border-left: 5px solid black !important;
    // }
}
.td-subj {
    min-width: 60%;
    padding-right: 0;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.td-time {
    padding-left: 0;
    padding-right: 10px;
    width: max-content;
    text-align: right;
}

.read-mail {
    // background: #fafafa !important;
    font-weight: 300 !important;

    .td-time,
    td:first-child {
        border-right: none !important;
        border-left: none !important;
    }

    .td-mail:hover {
        border-left: 3px solid !important;
    }

    .mail-subject {
        font-weight: 500;
    }
}
.glb-black {
    .read-mail {
        .td-mail:hover {
            border-left: 5px solid #ffffffb3 !important;
        }
    }
}

.glb-white {
    .read-mail {
        .td-mail:hover {
            border-left: 5px solid #000000b3 !important;
        }
    }
}

.list-mail-tr:hover {
    // background: #e5e5e5 !important;
    cursor: pointer;
}
</style>
